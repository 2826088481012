<template>
  <div>
    <v-snackbar
      v-model="success"
      color="success"
      >
      ¡Código QR descargado con éxito!
    </v-snackbar>

    <v-btn
      fixed
      fab
      bottom
      left
      @click="drawer = true"
      class="ml-n12"
      >
      <v-icon
        class="ml-6"
        >
        mdi-chevron-right
      </v-icon>
    </v-btn>
    <v-navigation-drawer 
      v-if="currentUser"
      v-model="drawer"
      :mobile-breakpoint="762"
      app
      >

      <v-list
        class="fill-height"
        dense
        nav
        >
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="title">
              {{ currentUser.store.name }}
            </v-list-item-title>
            <v-list-item-subtitle>
              Administración
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-subheader>
          Configuración de mi tienda
        </v-subheader>

        <v-list-item
          link
          to="/admin/settings"
          >
          <v-list-item-icon>
            <v-icon>mdi-store</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Mi tienda</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          to="/admin/categories"
          >
          <v-list-item-icon>
            <v-icon>mdi-file-tree</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Categorías</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group
          prepend-icon="mdi-package-variant-closed"
          no-action
          :value="true"
          >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Mis Productos</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            link
            to="/admin/products"
            >
            <v-list-item-icon>
              <v-icon>mdi-tshirt-crew</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Listado</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            link
            to="/admin/option_types"
            >
            <v-list-item-icon>
              <v-icon>mdi-shoe-formal</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Talla y color</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-item
          link
          to="/admin/shipping_methods"
          >
          <v-list-item-icon>
            <v-icon>mdi-truck-fast</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Métodos de envío</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          to="/admin/payment_methods"
          >
          <v-list-item-icon>
            <v-icon>mdi-cash-multiple</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Métodos de pago</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-subheader>
          Gestión de mi tienda
        </v-subheader>

        <v-list-item
          link
          to="/admin/orders"
          >
          <v-list-item-icon>
            <v-icon>mdi-shopping</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Órdenes</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          to="/admin/promotions"
          >
          <v-list-item-icon>
            <v-icon>mdi-tag-multiple</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Ofertas</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          to="/admin/analytics"
          >
          <v-list-item-icon>
            <v-icon>mdi-view-dashboard-variant</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Analíticas</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <qr
          @success="success = true"
        ></qr>

        <v-list-item
          link
          to="/admin/fairs"
          >
          <v-list-item-icon>
            <v-icon>mdi-string-lights</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Ferias</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          :to="{name: 'profile', params: {slug: currentUser.selectedProfile.slug}}"
          class="mt-1"
          >
          <v-list-item-icon>
            <v-icon>mdi-store</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Ir a mi tienda</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
          <!-- no nos gusta el botón de abajo asi que fiumba!!!11!!-->
        <v-list-item
          v-if="show" 
          link
          @click="$router.go(-1)"
          >
          <v-list-item-icon>
            <v-icon>mdi-chevron-left</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Volver</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import qr from '@/components/admin/shared/QR.vue'

export default {
  data () {
    return {
      show: false,
      drawer: false,
      success: false,
    }
  },

  mounted () {
    this.drawer = !this.$vuetify.breakpoint.mobile
  },

  computed: {
    ...mapGetters(['currentUser']),
  },

  components: { qr }
}
</script>
